<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الفوايتر المعدلة</h1>

      <v-row class="mt-5">
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.total_amount="{ item }">
              {{ numberWithComma(item.total_amount) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberWithComma(item.payment_amount) }}
            </template>
            <template v-slot:item.discount_amount="{ item }">
              {{ numberWithComma(item.discount_amount) }}
            </template>
            <template v-slot:item.remaining="{ item }">
              {{ numberWithComma(item.remaining) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="showEditedLog(item)">
                    fa-eye
                  </v-icon>
                </template>
                <span>عرض التفاصيل</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- delete dialog -->
    <v-dialog v-model="restoreDelete.open" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من استعادة هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="restoreDelete.open = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="restoreDelete.loading" @click="restoreItemConfirm">
            استعادة
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dialog -->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import salary_other_daily from '@/api/salary_other_daily'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'

export default {
  data() {
    return {
      rate: 0,

      today_statistics: {
        price: 0,
        payment_amount: 0,
        discount_amount: 0,
        remaining: 0,
      },

      account_type: null,

      errors: {},

      account_type: null,

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
      },

      restoreDelete: {
        open: false,
        loading: false,
        data: {},
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      tableOptions: {},

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
          },
          {
            text: 'اسم الزبون',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'نوع الخدمة',
            sortable: false,
            value: 'service_name',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },
          { text: 'المبلغ الكلي', sortable: false, value: 'total_amount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'payment_amount' },
          { text: 'الخصم', sortable: false, value: 'discount_amount' },
          { text: 'المتبقي', sortable: false, value: 'remaining' },
          { text: 'تاريخ الفاتورة', sortable: false, value: 'date' },
          { text: 'سبب التعديل', sortable: false, value: 'edit_reason' },
          { text: 'الملاحظات', sortable: false, value: 'desc' },
          { text: 'العمليات', sortable: false, value: 'actions' },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
  },

  created() {
    const account_type = this.$store.state.results.account_type

    this.account_type = account_type
  },

  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await salary_other_daily.get_edited_bills({
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page,
        limit: itemsPerPage,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    showEditedLog(item) {
      var id = item._id
      var customer_name = item.customer_name
      this.$router.push({ path: `/editedLog/${id}/${customer_name}` })
    },

    restoreItem(item) {
      this.restoreDelete.data = { ...item }
      this.restoreDelete.open = true
    },

    async restoreItemConfirm() {
      this.restoreDelete.loading = true

      const response = await salary_other_daily.restore_deleted(this.restoreDelete.data._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.restoreDelete.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.restoreDelete.loading = false
        this.restoreDelete.open = false
        this.getDataAxios()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isAllowedToDelete,

    numberWithComma,
  },
}
</script>
