var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"primary-shade--light pa-3"},[_c('h1',{staticClass:"text-center subtitle-4 black--text"},[_vm._v("الفوايتر المعدلة")]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"loading-text":"جاري التحميل ... الرجاء الانتظار","items":_vm.table.Data,"options":_vm.tableOptions,"server-items-length":_vm.table.totalData,"loading":_vm.table.loading,"footer-props":{
            itemsPerPageOptions: [10, 50, 100],
          }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item._id",fn:function(props){return [_vm._v(" "+_vm._s(_vm.table.totalData - props.index - (_vm.tableOptions.page - 1) * _vm.tableOptions.itemsPerPage)+" ")]}},{key:"item.total_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.total_amount))+" ")]}},{key:"item.payment_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.payment_amount))+" ")]}},{key:"item.discount_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.discount_amount))+" ")]}},{key:"item.remaining",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.remaining))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","size":"20"},on:{"click":function($event){return _vm.showEditedLog(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa-eye ")])]}}],null,true)},[_c('span',[_vm._v("عرض التفاصيل")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.restoreDelete.open),callback:function ($$v) {_vm.$set(_vm.restoreDelete, "open", $$v)},expression:"restoreDelete.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" هل انت متأكد من استعادة هذا العنصر ؟ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.restoreDelete.open = false}}},[_vm._v(" الغاء ")]),_c('v-btn',{attrs:{"color":"primary white--text","loading":_vm.restoreDelete.loading},on:{"click":_vm.restoreItemConfirm}},[_vm._v(" استعادة ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogData.open),callback:function ($$v) {_vm.$set(_vm.dialogData, "open", $$v)},expression:"dialogData.open"}},[_c('v-toolbar',{attrs:{"color":_vm.dialogData.color,"dense":""}}),_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.dialogData.bodyText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1 justify-start","text":""},on:{"click":function($event){_vm.dialogData.open = false}}},[_vm._v(" تم ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }